import './App.css';
import { Component } from 'react';

import logo from './images/logo.svg';
import github from './images/rs/git.svg';
import linkedin from './images/rs/lk.svg';

import git_black from './images/git_black.png';
import lk_black from './images/lk_black.png';

import backk from './images/back.png';


import blur_first from './images/blur_first.png';
import circle_blue from './images/circle_blue.png';
import scene from './images/room.png';
import cloud from './images/cloud.png';

//PROJECT IMG
import project1 from './images/projets/1.jpg';
import project2 from './images/projets/2.jpg';
import project3 from './images/projets/3.jpg';
import blur_more from './images/blur_more.png';

import arrow from './images/arrow.svg';


import input1 from  './images/icons/user_black.svg';
import input2 from  './images/icons/mail.svg';
import input3 from  './images/icons/business.svg';
import input4 from  './images/icons/phone.svg';
import input5 from  './images/icons/edit.svg';

import oval from  './images/oval.png';
import seb from  './images/seb.png';
import seb_hover from  './images/seb_hover.png';

import blur_footer from './images/blur_footer.png';

import arrow_blue from './images/arrow_blue.svg';
import envelop from './images/envelop.svg';
import phone_icon from './images/phone_icon.svg';



import fb_black from './images/rs/black/fb.png';
import lk_black_2 from './images/rs/black/lk.svg';
import insta_black from './images/rs/black/insta.svg';
import dribbble_black from './images/rs/black/dribbble.svg';

import dev from './images/code.svg';

import coeur from './images/coeur.png';


import ReactWOW from 'react-wow'
import "animate.css/source/animate.css";


export default class App extends Component {

  constructor() {
    super();

    this.state = {
      hover_img: false,
      name: undefined,
      mail: undefined,
      message: undefined,
      phone: undefined,
      disabled_button: false,
      error: false,
      success_form: false,
      theme_mode: false
    };

  }


  _scroll() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  componentDidMount() {

    this._scroll();

    // DIRTY

    document.querySelectorAll('.hover_button').forEach(button => {

      let div = document.createElement('div'),
      letters = button.textContent.trim().split('');
  
      function elements(letter, index, array) {
  
        let element = document.createElement('span'),
        part = (index >= array.length / 2) ? -1 : 1,
        position = (index >= array.length / 2) ? array.length / 2 - index + (array.length / 2 - 1) : index,
        move = position / (array.length / 2),
        rotate = 1 - move;
  
        element.innerHTML = !letter.trim() ? '&nbsp;' : letter;
        element.style.setProperty('--move', move);
        element.style.setProperty('--rotate', rotate);
        element.style.setProperty('--part', part);
  
        div.appendChild(element);
  
      }
  
      letters.forEach(elements);
  
      button.innerHTML = div.outerHTML;
  
      button.addEventListener('mouseenter', e => {
          if(!button.classList.contains('out')) {
            button.classList.add('in');
          }
      });
  
      button.addEventListener('mouseleave', e => {
          if(button.classList.contains('in')) {
              button.classList.add('out');
              setTimeout(() => button.classList.remove('in', 'out'), 950);
          }
      });
  
    });

  }


  _sendForm(e) {

    e.preventDefault();
    const { hover_img, name, phone, mail, business, message } = this.state;

    if(name && mail && message) {
      this.setState({
        hover_img: true,
        disabled_button: true
      }, () => {
        
        const obj = {
          name,
          phone: phone ? phone : 'N/C',
          mail,
          business: business ? business : 'N/C',
          message,
        };

        const putMethod = {
          method: 'PUT',
          headers: {
              'Content-Type' : 'application/json',
          },
          body: JSON.stringify(obj)
        }
      
        fetch('https://europe-west1-mardiapi.cloudfunctions.net/api/v1/contacts/form/seb', putMethod)
        .then(response => {
            response.json();
            console.log('reponse ?', response);
            this.input1.value = "";
            this.input2.value = "";
            this.input3.value = "";
            this.input4.value = "";
            this.input5.value = "";
            this.setState({
              disabled_button: false,
              mail: undefined,
              name: undefined,
              phone: undefined,
              message: undefined,
              business: undefined,
              success_form: true,
            });
        })
        .catch(err => {
          console.log('err', err)
        })

  
  
      })
    } else {
      this.setState({error: true}, () => {
        setTimeout(() => {
          this.setState({error: false})
        }, 3400);
      });
    }

  }

  _mardi() {
    window.location = "https://mardi.io";
  }

  _scrollForm() {
    this.form.scrollIntoView();
  }


  _switch() {
    console.log('change theme');
    this.setState({theme_mode: !this.state.theme_mode}, () => {

      const get_p_item = document.querySelectorAll("p, h1, h2, h3, a, li");
      if(this.state.theme_mode) {
        document.body.classList.add('black_theme');
        get_p_item && get_p_item.forEach(item => {
          item.classList.add('black_theme_content');
        });

      } else {
        document.body.classList.remove('black_theme');
        get_p_item && get_p_item.forEach(item => {
          item.classList.remove('black_theme_content');
        });
      }
    });
    
  }

  render() {

    const { theme_mode } = this.state;

    return (
      <div className="app_container">

        <img src={backk} alt="Developpeur web hossegor" className="img_backk" style={theme_mode ? {filter: 'invert(1)'} : {filter: 'invert(0)'}}/>
        {/* HEADER */}
        <header>
          <div className="left_header">
            <img src={logo} alt="#" className="logo" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
            <a href="https://mardi.io">mardi.io</a>
          </div>

          <nav>
            <ul>
              <li>
                <a href="#projets">projets</a>
              </li>
              <li>
                <a href="https://mardi.io" target="_blank">mardi.io</a>
              </li>
            </ul>
          </nav>

          <a 
            className="contact_header hover_button button"
            // onClick={() => this._scrollForm()}
            href="https://www.linkedin.com/in/s%C3%A9bastien-constans-2246b6107/"
            target="_blank"
          >
            Me contacter
          </a>
        </header>



        {/* FIRST SECTION */}

        <section className="container_full">

          <div className="center">

            <div className="flex_item">

              <h1>React / React Native</h1>

              <p>
              Développez votre identité et maîtrisez votre activité en ligne. Nous sommes une agence de communication et de marketing digital à Bordeaux et Hossegor.
              </p>

              <div className="flex_button">
                <a href="https://github.com/sebcnst" target="_blank" style={theme_mode ? {borderColor: 'white'} : {borderColor: 'black'}}>
                  <img src={github} alt="#" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
                </a>

                <a href="https://www.linkedin.com/in/s%C3%A9bastien-constans-2246b6107/" target="_blank">
                  <img src={linkedin} alt="#" />
                </a>
              </div>

            </div>

            <div className="flex_item">

            <ReactWOW animation="fadeIn">
            <img src={circle_blue} alt="#" className="world"/>
             </ReactWOW>

            <ReactWOW animation="fadeIn" delay="250ms">
                <img src={blur_first} alt="#" className="background_blur" style={theme_mode ? {opacity: 0.3} : {opacity: 1}}/>
             </ReactWOW>

            <ReactWOW animation="fadeIn" delay="500ms">
              <img src={cloud} alt="#" className="cloud"/>
            </ReactWOW> 

            <ReactWOW animation="fadeIn" delay="750ms">
              <img src={scene} alt="#" className="scene"/>
            </ReactWOW> 


            </div>

          </div>


        </section>



        {/* SECTION ABOUT */}

        <section className="container_about">

            <div className="content_about">
                <ReactWOW animation="fadeIn">
                  <h2>Développons ensemble toutes ces choses et <span className="span_mode" onClick={() => this._switch()}>+</span> !</h2>
                </ReactWOW>

                <ReactWOW animation="fadeIn" delay="250ms">
                <p style={theme_mode ? {color: 'white'} : {color: 'black'}}>
                Donnons enfin la vie à votre projet. Intégrons les maquettes de celui-ci tout en y fournissant le dynamisme et la fluidité qui raviront vos utilisateurs. Le tout avec une interface d'administration entièrement personnalisée pour vous donner la fierté et l'envie de travailler avec votre nouvelle solution.
                </p>
                </ReactWOW>
            </div>

            <div className="skill_about">

            <ReactWOW animation="fadeIn">
              <div className="item_skill">
                <div className="title_skill" style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                  <h3 style={theme_mode ? {color: 'white'} : {color: 'black'}}>Simplifié</h3>
                </div>

                <ul className="list_skill gradient-border" style={{opacity: 1, top: 0}}>
                  <div className="bg_white" style={theme_mode ? {backgroundColor: '#5c5b5b'} : {backgroundColor: 'white'}}></div>
                  <li><span></span>Site vitrine</li>
                  <li><span style={{backgroundColor: '#78FF4F'}}></span>Site e-commerce</li>
                  <li><span style={{backgroundColor: '#4FE0FF'}}></span>Applications mobiles</li>
                  <li><span style={{backgroundColor: '#814FFF'}}></span>Automatiser ERP/CRM</li>
                </ul>

              </div>
            </ReactWOW>
              
            <ReactWOW animation="fadeIn" delay="300ms">
              <div className="item_skill">
                <div className="title_skill" style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                  <h3 style={theme_mode ? {color: 'white'} : {color: 'black'}}>Front-end/Back-end</h3>
                </div>

                <ul className="list_skill gradient-border">
                  <div className="bg_white" style={theme_mode ? {backgroundColor: '#5c5b5b'} : {backgroundColor: 'white'}}></div>
                  <li><span></span>HTML/CSS</li>
                  <li><span style={{backgroundColor: '#78FF4F'}}></span>Javascript</li>
                  <li><span style={{backgroundColor: '#4FE0FF'}}></span>API REST</li>
                  <li><span style={{backgroundColor: '#814FFF'}}></span>AWS / Firebase</li>
                </ul>

              </div>
            </ReactWOW>
            
            <ReactWOW animation="fadeIn" delay="600ms">
              <div className="item_skill">
                <div className="title_skill" style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                  <h3><img src={coeur} alt="#"/></h3>
                </div>

                <ul className="list_skill gradient-border">
                  <div className="bg_white" style={theme_mode ? {backgroundColor: '#5c5b5b'} : {backgroundColor: 'white'}}></div>
                  <li><span></span>React</li>
                  <li><span style={{backgroundColor: '#78FF4F'}}></span>React Native</li>
                  <li><span style={{backgroundColor: '#4FE0FF'}}></span>Typescript</li>
                  <li style={{opacity: 0}}><span style={{backgroundColor: '#4FE0FF'}}></span>Typescript</li>
                </ul>

              </div>
            </ReactWOW>
          </div>
        </section>

        {/* PROJECTS */}

        <section className="container_projects" id="projets">

        <ReactWOW animation="fadeIn" delay="250ms">
          <h2 style={theme_mode ? {color: 'white'} : {color: 'black'}}>J'ai collaboré<br></br>avec ces entreprises</h2>
        </ReactWOW>

          <div className="mansonry_projects">

          <ReactWOW animation="fadeIn">
            <div className="flex_projects">
              <div className="item_project">
                <img src={project1} alt="#" className="bg_project"/>

                <div className="gradient_project"></div>

                <div className="item_bottom_project">
                  <h3>Dock G6</h3>
                  <p>Client : Dock G6</p>

                  <div className="tags_flex">
                    <div className="tag_item dev">
                      <img src={dev} alt="#"/>
                      Site internet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

            <div className="flex_projects">

            <ReactWOW animation="fadeIn" delay="250ms">
              <div className="item_project">
                <img src={project2} alt="#" className="bg_project"/>
                <div className="gradient_project"></div>
                <div className="item_bottom_project">
                  <h3>LPS France</h3>
                  <p>Client : LPS France</p>

                  <div className="tags_flex">
                    <div className="tag_item dev">
                      <img src={dev} alt="#"/>
                      Applications mobiles
                    </div>
                  </div>

                </div>
              </div>
            </ReactWOW>


            <ReactWOW animation="fadeIn" delay="500ms">
              <div className="item_project item_more" onClick={() => this._mardi()}>
                <img src={blur_more} alt="#" className="bg_project blur_project"/>
                
                <p>Vous voulez en voir plus ?</p>

                <a href="https://mardi.io" target="_blank">
                  <img src={arrow} alt="#"/>
                </a>
              </div>
            </ReactWOW>

            <ReactWOW animation="fadeIn" delay="400ms">
              <div className="item_project">
                <img src={project3} alt="#" className="bg_project"/>
                <div className="gradient_project"></div>
                <div className="item_bottom_project">
                  <h3>By Marco</h3>
                  <p>Client : By Marco</p>

                  <div className="tags_flex">
                    <div className="tag_item dev">
                      <img src={dev} alt="#"/>
                      Site internet
                    </div>
                  </div>
                </div>
              </div>
              </ReactWOW>
          </div>
        </div>

      </section>


        {/* <section className="container_contact" ref={el => this.form = el}>
          <ReactWOW animation="fadeIn">
            <h2 style={theme_mode ? {color: 'white'} : {color: 'black'}}>Reston en<br></br>contact !</h2>
          </ReactWOW>

          <div className="flex_contact">

          <ReactWOW animation="fadeIn">
            <div className="contact_item" style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>

                <form>

                  { 
                    success_form &&
                    <p className="sucess_green">Message envoyé avec succès, à bientôt !</p>
                  }

                  <div className="input_div">
                    <img src={input1} alt="#" style={this.state.name ? {opacity: 1} : {opacity: 0.4}}/>
                    <input ref={el => this.input1 = el} type="text" placeholder="Nom" onChange={(e) => this.setState({name: e.target.value})} style={this.state.name ? {borderColor: 'black'} : {borderColor: '#D8D8D8'}}/>
                  </div>

                  <div className="input_div">
                    <img src={input2} alt="#" style={this.state.mail ? {opacity: 1} : {opacity: 0.4}}/>
                    <input ref={el => this.input2 = el} type="text" placeholder="Adresse e-mail" onChange={(e) => this.setState({mail: e.target.value})} style={this.state.mail ? {borderColor: 'black'} : {borderColor: '#D8D8D8'}}/>
                  </div>

                  <div className="input_div">
                    <img src={input4} alt="#" style={this.state.phone ? {opacity: 1} : {opacity: 0.4}}/>
                    <input ref={el => this.input3 = el} type="text" placeholder="Téléphone" onChange={(e) => this.setState({phone: e.target.value})} style={this.state.phone ? {borderColor: 'black'} : {borderColor: '#D8D8D8'}}/>
                  </div>

                  <div className="input_div">
                    <img src={input3} alt="#" style={this.state.business ? {opacity: 1} : {opacity: 0.4}}/>
                    <input ref={el => this.input4 = el} type="text" placeholder="Société" onChange={(e) => this.setState({business: e.target.value})} style={this.state.business ? {borderColor: 'black'} : {borderColor: '#D8D8D8'}}/>
                  </div>

                  <div className="input_div">
                    <img src={input5} alt="#" style={this.state.message ? {opacity: 1} : {opacity: 0.4}}/>
                    <input ref={el => this.input5 = el} type="text" placeholder="Votre message" onChange={(e) => this.setState({message: e.target.value})} style={this.state.message ? {borderColor: 'black'} : {borderColor: '#D8D8D8'}}/>
                  </div>

                </form>

                <button
                  className={["hover_button button", error ? "  error_display " : " hover_button button "]}
                  onClick={(e) => this._sendForm(e)}
                  disabled={disabled_button ? true : false}
                  style={disabled_button ? {opacity: 0.4} : {opacity: 1}}
                >
                  {
                    error ? (
                    'Erreur...'
                    ) : (
                      'Envoyer'
                    )
                  }

          
                </button>


            </div>
          </ReactWOW>


            <div className="contact_item">
              
              <ReactWOW animation="fadeIn">
                <img src={oval} alt="#" className="oval_blue"/>
              </ReactWOW>

              <ReactWOW animation="fadeIn" delay="250ms">
              <div
                className="card_team"
                onMouseEnter={() => this.setState({hover_img: true})}
                onMouseLeave={() => this.setState({hover_img: false})}
                style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}
              >

                {
                  hover_img ? (
                    <img src={seb_hover} alt="#" className="seb_hover"/>
                  )   :   (
                    <img src={seb} alt="#" className="seb"/>
                  )
                }

                <div className="info_team">
                  <h3>Sébastien Constans</h3>
                  <p><span style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}s></span>Développeur web & mobile</p>

                  <div className="team_rs">
                    <a href="https://github.com/sebcnst" target="_blank" without rel="noreferrer">
                      <img src={git_black} alt="#" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
                    </a>
                    <a href="https://www.linkedin.com/in/s%C3%A9bastien-constans-2246b6107/" target="_blank">
                      <img src={lk_black} alt="#" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
                    </a>
                  </div>
                </div>
              </div>
              </ReactWOW>

            </div>
          </div>
        </section>
 */}


        <footer>

          <img src={blur_footer} alt="#" className="footer_blur" style={theme_mode ? {filter: 'invert(1)'} : {filter: 'invert(0)'}}/>

          <div className="center_footer">

            <div className="flex_footer">

              <div className="item_footer">
                <img src={logo} alt="#" className="logo_footer" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
                <a href="https://mardi.io" target="_blank" without rel="noreferrer">mardi.io</a>
              </div>

              <a className="a_top" onClick={() => this._scroll()} style={theme_mode ? {backgroundColor: 'rgba(255, 255, 255, 0.2)'} : {backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <img src={arrow_blue} alt="#"/>
              </a>

            </div>


            <div className="flex_footer">
              <ul className="item_footer">
                <li>
                  <a href="#projets">projets</a>
                </li>
                <li>
                  <a href="https://mardi.io" target="_blank" without rel="noreferrer">mardi.io</a>
                </li>
              </ul>

              <ul className="item_footer item_coord">
                <li>
                  <a href="mailto:sebastien@mardi.io">
                    <img src={envelop} alt="#"/>
                    sebastien@mardi.io
                  </a>
                </li>
              </ul>
            </div>


            {/* Mentions légales + RS */}
            <div className="flex_footer flex_mentions">
              <ul className="item_footer">
                <li>
                  <a style={{opacity: 0.69}}>&copy; sebcnst.fr</a>
                </li>
              </ul>

              <ul className="item_footer footer_rs">
                <li>
                  <a href="https://www.linkedin.com/in/s%C3%A9bastien-constans-2246b6107/" target="_blank" without rel="noreferrer">
                    <img src={lk_black_2} alt="#" style={theme_mode ? {filter: 'invert(100%)'} : {filter: 'invert(0%)'}}/>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </footer>

      </div>
    )
  }

}
